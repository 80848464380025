// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---docs-concepts-mdx": () => import("./../../../docs/concepts.mdx" /* webpackChunkName: "component---docs-concepts-mdx" */),
  "component---docs-getting-started-mdx": () => import("./../../../docs/Getting-Started.mdx" /* webpackChunkName: "component---docs-getting-started-mdx" */),
  "component---docs-home-mdx": () => import("./../../../docs/Home.mdx" /* webpackChunkName: "component---docs-home-mdx" */),
  "component---docs-middleware-mdx": () => import("./../../../docs/Middleware.mdx" /* webpackChunkName: "component---docs-middleware-mdx" */),
  "component---docs-release-notes-mdx": () => import("./../../../docs/Release-Notes.mdx" /* webpackChunkName: "component---docs-release-notes-mdx" */),
  "component---docs-retries-and-queues-mdx": () => import("./../../../docs/retries-and-queues.mdx" /* webpackChunkName: "component---docs-retries-and-queues-mdx" */),
  "component---docs-streaming-frameworks-comparison-mdx": () => import("./../../../docs/Streaming-Frameworks-Comparison.mdx" /* webpackChunkName: "component---docs-streaming-frameworks-comparison-mdx" */),
  "component---docs-upgrade-guide-mdx": () => import("./../../../docs/Upgrade-guide.mdx" /* webpackChunkName: "component---docs-upgrade-guide-mdx" */),
  "component---docs-use-cases-nearby-driver-service-mdx": () => import("./../../../docs/Use-Cases:Nearby-Driver-Service.mdx" /* webpackChunkName: "component---docs-use-cases-nearby-driver-service-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

